import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NavbarComponent } from "./component/navbar/navbar.component";
import { FooterComponent } from "./component/footer/footer.component";
import { ProductsRelatedComponent } from "./component/products-related/products-related.component";
import { SideFiltersComponent } from "./component/side-filters/side-filters.component";
import { SearchContainerComponent } from "./component/search-container/search-container.component";
import { RecentlyViewedComponent } from "./component/recently-viewed/recently-viewed.component";
import { RightSideProductsComponent } from "./component/right-side-products/right-side-products.component";
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { IvyCarouselModule } from "angular-responsive-carousel";
import { AppRoutingModule } from "./app-routing.module";

@NgModule({
  declarations: [
    NavbarComponent,
    FooterComponent,
    ProductsRelatedComponent,
    SideFiltersComponent,
    SearchContainerComponent,
    RecentlyViewedComponent,
    RightSideProductsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    IvyCarouselModule,
    NgxSliderModule,
  ],
  exports: [
    NavbarComponent,
    FooterComponent,
    ProductsRelatedComponent,
    SideFiltersComponent,
    SearchContainerComponent,
    RecentlyViewedComponent,
    RightSideProductsComponent,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    IvyCarouselModule,
    NgxSliderModule,
  ],
})
export class SharedComponentsModule {}
