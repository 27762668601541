import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './component/navbar/navbar.component';
import { FooterComponent } from './component/footer/footer.component';
import {IvyCarouselModule} from 'angular-responsive-carousel';
import { SearchContainerComponent } from './component/search-container/search-container.component';
import { SideFiltersComponent } from './component/side-filters/side-filters.component';
import { ProductsRelatedComponent } from './component/products-related/products-related.component';
import { RecentlyViewedComponent } from './component/recently-viewed/recently-viewed.component';
import { RightSideProductsComponent } from './component/right-side-products/right-side-products.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { FormsModule } from '@angular/forms';
import { SharedComponentsModule } from './SharedComponentsModule';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    SharedComponentsModule,
    IvyCarouselModule,
    RouterModule,
    FormsModule,
    NgxSliderModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
