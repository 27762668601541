import { Component, OnInit } from '@angular/core';
import { Options, LabelType } from "@angular-slider/ngx-slider";

@Component({
  selector: 'app-side-filters',
  templateUrl: './side-filters.component.html',
  styleUrls: ['./side-filters.component.scss']
})
export class SideFiltersComponent implements OnInit {

  isOpen = false;
  minValue: number = 100;
  maxValue: number = 400;
 
  constructor() {}

  ngOnInit(): void {}
  sidebarToggle() {
    this.isOpen = !this.isOpen;
  }
  options: Options = {
    floor: 0,
    ceil: 500,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return "<b>Min price:</b> ₦" + value;
        case LabelType.High:
          return "<b>Max price:</b> ₦" + value;
        default:
          return "₦" + value;
      }
    }
  }
}
