import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-products-related",
  templateUrl: "./products-related.component.html",
  styleUrls: ["./products-related.component.scss"],
})
export class ProductsRelatedComponent implements OnInit {
  lists = ["", "one", "four", "two"];
  constructor() {}

  ngOnInit(): void {}
}
